@charset "utf-8";

$brand-color:           #999999 !default;
$brand-color-light:     lighten($brand-color, 5%) !default;
$brand-color-dark:      darken($brand-color, 35%) !default;

$site-title-color:      $brand-color-light !default;

$text-color:            #bbbbbb !default;
$background-color:      #333333 !default;
$code-background-color: #212121 !default;

$link-base-color:       #79b8ff !default;
$link-visited-color:    $link-base-color !default;
$link-hover-color:      $text-color !default;

$border-color-01:       $brand-color-dark !default;
$border-color-02:       $brand-color-light !default;
$border-color-03:       $brand-color !default;

$table-text-color:      $text-color !default;
$table-zebra-color:     lighten($background-color, 4%) !default;
$table-header-bg-color: lighten($background-color, 10%) !default;
$table-header-border:   lighten($background-color, 21%) !default;
$table-border-color:    $border-color-01 !default;


// Syntax highlighting styles should be adjusted appropriately for every "skin"
// List of tokens: https://github.com/rouge-ruby/rouge/wiki/List-of-tokens
// Some colors come from Material Theme Darker:
// https://github.com/material-theme/vsc-material-theme/blob/master/scripts/generator/settings/specific/darker-hc.ts
// https://github.com/material-theme/vsc-material-theme/blob/master/scripts/generator/color-set.ts
// ----------------------------------------------------------------------------

.highlight {
  .c     { color: #545454; font-style: italic } // Comment
  .err   { color: #f07178; background-color: #e3d2d2 } // Error
  .k     { color: #89DDFF; font-weight: bold } // Keyword
  .o     { font-weight: bold } // Operator
  .cm    { color: #545454; font-style: italic } // Comment.Multiline
  .cp    { color: #545454; font-weight: bold } // Comment.Preproc
  .c1    { color: #545454; font-style: italic } // Comment.Single
  .cs    { color: #545454; font-weight: bold; font-style: italic } // Comment.Special
  .gd    { color: #000; background-color: #fdd } // Generic.Deleted
  .gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
  .ge    { font-style: italic } // Generic.Emph
  .gr    { color: #f07178 } // Generic.Error
  .gh    { color: #999 } // Generic.Heading
  .gi    { color: #000; background-color: #dfd } // Generic.Inserted
  .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
  .go    { color: #888 } // Generic.Output
  .gp    { color: #555 } // Generic.Prompt
  .gs    { font-weight: bold } // Generic.Strong
  .gu    { color: #aaa } // Generic.Subheading
  .gt    { color: #f07178 } // Generic.Traceback
  .kc    { font-weight: bold } // Keyword.Constant
  .kd    { font-weight: bold } // Keyword.Declaration
  .kp    { font-weight: bold } // Keyword.Pseudo
  .kr    { font-weight: bold } // Keyword.Reserved
  .kt    { color: #FFCB6B; font-weight: bold } // Keyword.Type
  .m     { color: #F78C6C } // Literal.Number
  .s     { color: #C3E88D } // Literal.String
  .na    { color: #008080 } // Name.Attribute
  .nb    { color: #EEFFFF } // Name.Builtin
  .nc    { color: #FFCB6B; font-weight: bold } // Name.Class
  .no    { color: #008080 } // Name.Constant
  .ni    { color: #800080 } // Name.Entity
  .ne    { color: #900; font-weight: bold } // Name.Exception
  .nf    { color: #82AAFF; font-weight: bold } // Name.Function
  .nn    { color: #555 } // Name.Namespace
  .nt    { color: #FFCB6B } // Name.Tag
  .nv    { color: #EEFFFF } // Name.Variable
  .ow    { font-weight: bold } // Operator.Word
  .w     { color: #EEFFFF } // Text.Whitespace
  .mf    { color: #F78C6C } // Literal.Number.Float
  .mh    { color: #F78C6C } // Literal.Number.Hex
  .mi    { color: #F78C6C } // Literal.Number.Integer
  .mo    { color: #F78C6C } // Literal.Number.Oct
  .sb    { color: #C3E88D } // Literal.String.Backtick
  .sc    { color: #C3E88D } // Literal.String.Char
  .sd    { color: #C3E88D } // Literal.String.Doc
  .s2    { color: #C3E88D } // Literal.String.Double
  .se    { color: #EEFFFF } // Literal.String.Escape
  .sh    { color: #C3E88D } // Literal.String.Heredoc
  .si    { color: #C3E88D } // Literal.String.Interpol
  .sx    { color: #C3E88D } // Literal.String.Other
  .sr    { color: #C3E88D } // Literal.String.Regex
  .s1    { color: #C3E88D } // Literal.String.Single
  .ss    { color: #C3E88D } // Literal.String.Symbol
  .bp    { color: #999 } // Name.Builtin.Pseudo
  .vc    { color: #FFCB6B } // Name.Variable.Class
  .vg    { color: #EEFFFF } // Name.Variable.Global
  .vi    { color: #EEFFFF } // Name.Variable.Instance
  .il    { color: #F78C6C } // Literal.Number.Integer.Long
}
